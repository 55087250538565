import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Content, Title1 }
  from '../components/Home/homeStyledComponents';
import MenuBar from '../components/Home/MenuBar';
import LaRubriqueTopBlock from '../components/LaRubrique/LaRubriqueTopBlock';
import LaRubriqueBottomBlock from '../components/LaRubrique/LaRubriqueBottomBlock';
import SocialNetworks from '../components/Home/SocialNetworks';
import Footer from '../components/Home/Footer';
import Layout from '../layouts/index';
import isMobile from '../services/isMobile';

const LaRubriquePostTemplate = ({ data: { laRubriquePostsJson: post } }) => (
  <Layout routeSlug={`LaRubrique_${post.slug}`}>
    <Container>
      <Content>
        <MenuBar />
        <Title1>Rubrique Tilli</Title1>
        <LaRubriqueTopBlock
          keywords={post.keywords}
          image={post.topImage && post.topImage.publicURL}
          imageMobile={post.topImageMobile && post.topImageMobile.publicURL}
          imageAlt={post.topImageAlt}
          imageText={post.topImageText}
          imageText2={post.topImageText2}
          style={isMobile() ?
            post.topImageTextMobileStyle :
            post.topImageTextStyle
          }
        />
        <LaRubriqueBottomBlock
          text1={post.row1Text}
          image1={post.row1Image && post.row1Image.publicURL}
          image1Alt={post.row1ImageAlt}
          text2={post.row2Text}
          image2={post.row2Image && post.row2Image.publicURL}
          image2Mobile={post.row2ImageMobile && post.row2ImageMobile.publicURL}
          image2Alt={post.row2ImageAlt}
          image2TopMargin={post.row2ImageTopMargin}
          image2LeftMargin={post.row2ImageLeftMargin}
          p2LeftMargin={post.p2LeftMargin}
          image2Text={post.row2ImageText}
          image2TextStyle={isMobile() ?
            post.row2ImageTextMobileStyle :
            post.row2ImageTextStyle
          }
        />
        <SocialNetworks />
        <Footer />
      </Content>
    </Container>
  </Layout>
);


LaRubriquePostTemplate.propTypes = {
  data: PropTypes.shape({
    laRubriquePostsJson: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LaRubriquePostTemplate;

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query MyQueryName($path: String!) {
    laRubriquePostsJson(path: { eq: $path }) {
      id
      slug
      path
      title
      keywords
      topImage {
        publicURL
      }
      topImageMobile {
        publicURL
      }
      topImageAlt
      topImageText
      topImageText2
      topImageTextStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      topImageTextMobileStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      row1Text
      row1Image {
        publicURL
      }
      row1ImageAlt
      row2Text
      row2Image {
        publicURL
      }
      row2ImageMobile {
        publicURL
      }
      row2ImageAlt
      row2ImageTopMargin
      row2ImageLeftMargin
      p2LeftMargin
      row2ImageText
      row2ImageTextStyle {
        fontSize
        textAlign
        margin
      }
        row2ImageTextMobileStyle {
        fontSize
        textAlign
        margin
      }
    }
  }
`;
