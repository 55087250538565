import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, margins, fontSizes, mobileThresholdPixels, contentWidth }
  from '../../components/Home/v2/StyledComponents';
import { toFormattedColoredText } from '../../services/formatting';
import isMobile from '../../services/isMobile';

const Container = styled.div`
  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
    align-items: center;
    width: 100vw;
  }
`;

const keywordsWidth = 300;
const LeftBlockContainer = styled.div`
  width: ${keywordsWidth}px;
  margin-top: ${margins.l};
  margin-right: ${margins.m};
`;

const KeywordsContainer = styled.div`
  margin-top: ${margins.m};
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
  }
`;

const Keyword = styled.span`
  color: #1a2b3f;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const ImgContainer = styled.div`
  display: flex;
  width: ${contentWidth - keywordsWidth}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50%;
    object-fit: cover;
  }
`;

const ImgText = styled.span`
  font-size: ${props => props.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  margin: ${props => props.margin};
`;

const Title3 = styled.h3`
  text-align: left;
  width: 100%;
  color: ${colors.navy};
  font-size: 24px;
  font-weight: 300;
  ${props => !props.margin && 'margin: 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: 20px;
  }
`;

const LaRubriqueTopBlock = ({
  keywords, image, imageMobile, imageAlt, imageText, imageText2, style,
}) => (
  <Container>
    <Content>
      <LeftBlockContainer>
        <Title3>⋅ Mots-clés ⋅</Title3>
        <KeywordsContainer>
          {keywords.map(keyword => <Keyword key={keyword}>{`#${keyword} `}</Keyword>)}
        </KeywordsContainer>
      </LeftBlockContainer>
      <ImgContainer>
        <Img src={isMobile() ? imageMobile : image} alt={imageAlt} />
        <ImgText margin={style.margin} fontSize={style.fontSize}>
          {toFormattedColoredText(imageText, 'yellow')}
        </ImgText>
        {imageText2 &&
          <ImgText margin={style.margin2} fontSize={style.fontSize2}>
            {toFormattedColoredText(imageText2)}
          </ImgText>
        }
      </ImgContainer>
    </Content>
  </Container>
);

LaRubriqueTopBlock.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageText: PropTypes.string.isRequired,
  imageText2: PropTypes.string,
  style: PropTypes.shape({
    fontSize: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
    fontSize2: PropTypes.string,
    margin2: PropTypes.string,
  }).isRequired,
};

LaRubriqueTopBlock.defaultProps = {
  imageText2: '',
};

export default LaRubriqueTopBlock;
